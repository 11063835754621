body#credit-application {
    #application-logo {
        width: 300px;
        max-width: 90%;
    }
    .card-body {
        label {
            font-weight: bold;
        }
    }
    .application-card-body {
        padding: 0;
    }
    .application-progress {
        margin: 0;
        padding: 0;
        list-style: none;
        a {
            display: block;
            padding: 0.5rem 1.25rem;
            color: $black;
            &:not(.disabled):hover {
                text-decoration: none;
                background: $c-brand-primary;
            }
            &.current {
                &:before {
                    content: '> ';
                }
            }
        }
        li {
            &:not(:last-child) {
                border-bottom: 1px solid #ccc;
            }
        }
        .not-active {
            color: rgba(0, 0, 0, .4);
        }
        .current {
            font-weight: bold;
        }
        .disabled {
            pointer-events: none;
            &:not(.current) {
                background: #eaeaea;
                color: rgba(0, 0, 0, .45);
            }
        }
    }
    .info-panel {
        padding: 1em;
        .terms {
            //padding: 1em;
            background: #f7f7f7;
            font-size: 12px;
            p {
                padding: 1em;
                margin: 0;
            }
        }
        .accept {
            padding: 1em;
            font-size: 14px;
            font-weight: bold;
        }
    }
    #signature-pad {
        position: relative;
        &:after {
            content: 'Authorized Signature';
            position: absolute;
            bottom: 20%;
            left: 0;
            display: block;
            width: 100%;
            max-width: 394px;
            height: 2px;
            padding-left: 15px;
            font-size: 12px;
            background-color: black;
        }
    }
    canvas {
        max-width: 100%;
        height: 200px;
        border: 1px solid rgba(0, 0, 0, .25);
        &.error {
            border: 1px solid red;
        }
    }

    .touch {
        display: none;
    }

    @media (hover: hover) {
        .touch {
            display: none;
        }
    }

    @media (hover: none) {
        .touch {
            display: block;
        }
        .mouse {
            display: none;
        }
    }
}

.mw-vw-6 {
    min-width: 6vw;
}

.hide {
    position: absolute !important;
    top: -9999px !important;
    left: -9999px !important;
 }