.navbar-dark {
    background-color: #575555;
    .navbar-nav a.nav-link {
        text-decoration: underline;
        color: $c-brand-primary;
        &:hover {
            color: lighten($c-brand-primary, 30%);
        }
    }
}

.navbar-brand {
    padding: .22rem x;
    font-size: 22px;
    font-family: $f-stack-serif;
    font-weight: bold;
}
