.btn-brand {
	font-weight: 700;
	line-height: 24px;
	border: 1px solid $c-brand-primary;
	background: $c-brand-primary;
	color: #231f20;
    &:hover,
    &:not(:disabled):not(.disabled):active,
    &:focus {
        border: 1px solid $c-brand-primary;
        background: $c-brand-primary;
        color: #fff;
    }
}
