body#admin-dashboard {
    #filter-logo {
        width: 300px;
    }
    label {
        display: block;
    }
}

#applications-list {
    .app {
        &-header {
            background: $c-brand-primary;
        }
        &-row {
            border-bottom: 2px solid #ddd;
        }
        &-link {
            text-decoration: underline;
        }
        &-status {
            font-weight: bold;
            text-transform: uppercase;
            &--old {
                color: $danger;
            }
            &--new {
                color: $blue;
            }
            &--approved {
                color: $green;
            }
            &--need-info {
                color: $orange;
            }
            &--declined {
                color: $danger;
            }
        }
    }
}
