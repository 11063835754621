body#applicant-dashboard {
    .field-pair {
        font-size: 1.2rem;
    }
    .app-status {
        font-weight: bold;
        text-transform: uppercase;
        &--new {
            color: $blue;
        }
        &--approved {
            color: $green;
        }
        &--need-info {
            color: $orange;
        }
        &--declined {
            color: $danger;
        }
    }
    canvas {
        max-width: 100%;
        height: 200px;
        border: 1px solid rgba(0, 0, 0, .25);
        &.error {
            border: 1px solid red;
        }
    }

    .touch {
        display: none;
    }

    @media (hover: hover) {
        .touch {
            display: none;
        }
    }

    @media (hover: none) {
        .touch {
            display: block;
        }
        .mouse {
            display: none;
        }
    }
}
