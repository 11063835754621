body#login {
    background-image: radial-gradient(#ffffff 0%, rgba(254, 254, 254, 0.99) 22%, rgba(245, 245, 245, 0.95) 38%, rgba(241, 241, 241, 0.92) 60%, rgba(190, 190, 190, 0.08) 100%, #808080 100%);
    #app {
        display: flex;
        min-height: calc(100vh - 55px);
        align-items: center;
        justify-content: center;
    }
    main {
        width: 100%;
    }
    .card {
        width: 504px;
        max-width: 100%;
    }
    .card-header {
        font-family: $f-stack-serif;
        font-weight: 700;
        font-size: 20px;
        background: $c-brand-primary;
    }
    .btn-primary {
        min-width: 100px;
    }
    #login-logo {
        width: 503px;
        max-width: 100%;
        margin: x x 52px;
    }
}
